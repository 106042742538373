import React, { useContext, useState, useEffect } from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import Typist from "react-typist"
import Img from 'gatsby-image';

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/global-context-provider"

const IndexPage = ({ site }: any) => {
  const dispatch: any = useContext(GlobalDispatchContext)
  const state: any = useContext(GlobalStateContext)

  useEffect(() => {
    fetch('https://w2650z904k.execute-api.us-east-2.amazonaws.com/Dev/api/item', {
      headers: {
        'x-api-key': 'HcAOoHXlxf7cOhXUBiM8k3iVxn124f912BKJc39h'
      }
    })
      .then((res: any) => res.json())
      .then((response: any) => {
        let affirmationString: string = "";
        if (!response || !response.affirmation) {
          affirmationString = "Somithing positive.";
        } else {
          affirmationString = response.Affirmation;
        }
        dispatch({ type: "SET_AFFIRMATION", affirmation: affirmationString });
      })
  }, []);

  const blogDataResponse = useStaticQuery(graphql`
    query homeSanityBlogPostQuery {
        allSanityPost {
            nodes {
              id
              _rawExcerpt
              authors {
                author {
                  name
                }
              }
              mainImage {
                asset {
                  url
                  fluid(maxHeight: 400, maxWidth: 400){
                    ...GatsbySanityImageFluid
                  }
                }
              }
              title
              publishedAt
              slug{
                  current
              }
            }
          }
      }
    `);

  const blogData = blogDataResponse.allSanityPost.nodes || [];

  const onBlogPostClick = (node: any): void => {
    navigate(`/blog/${node.slug.current}`);
  }

  return (
    <Layout>
      <SEO
        title="Home #qdr_tech"
        description="Homepage for http://qdrtech.com, portfolio and personal blog for Quenton Radford."
        lang="eng"
        twiterTitle="Home #qdr_tech"
        twitterCard="Homepage for http://qdrtech.com, portfolio and personal blog for Quenton Radford."
        twitterDescription="Personal Blog and Porfolio for Quenton Radford."
        twitterSummary="Homepage for http://qdrtech.com, portfolio and personal blog for Quenton Radford."
      />
      <div className="container mx-auto">
        <div className="w-3/4 flex-1 m-auto justify-center">
          <p className="text-2xl">Quenton Radford</p>

          <p className="text-sm text-left">I am a Full Stack Software Engineer based out of Southern California. I enjoy writing code, reading, and self improvement.  Welcome to my blog and digital footprint. find me here.  </p>
        </div>
        <div className="w-3/4 flex-1 m-auto justify-center">
          <div className="flex flex-row p-2 m-2 justify-center flex-grow border-gray-400 border-b-2">
            <a href="https://github.com/qdrtech"><svg role="img" className="w-4 h-4 m-2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" /></svg></a>
            <a href="https://twitter.com/qdr_tech"><svg role="img" className="w-4 h-4 m-2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Twitter icon</title><path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z" /></svg></a>
          </div>
        </div>
        <div className="w-3/4 flex-1 m-auto justify-center">
          <div className="overflow-hidden rounded">
            <div className="px-6 box my-16">
              <div className="text-center font-mono text-4xl text-black">
                {!state || !state.affirmation ?
                  'Loading...'
                  :
                  <Typist
                    cursor={{
                      hideWhenDone: true,
                    }}>
                    <Typist.Delay ms={1250} />
                    {state.affirmation}
                  </Typist>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="w-3/4 flex-1 m-auto justify-center">
          <div className="grid flex">
            <div className="flex-1 box-content p-2 m-2">
              <p className="text-2xl text-center text-bold text-mono">
                {blogData && blogData.length > 0 ? "Blog Posts" : ""}
              </p>
            </div>
            <div className="flex-1 box-content p-2 m-2">
              {
                blogData && blogData.length > 0 ?
                  blogData.map((blog: any, index: number) => {
                    return (
                      <div className="container flex flex-col">
                        <div key={index} className="box-content p-4 rounded-lg shadow-md mb-5">
                          <div className="flex-1 mb-5">
                            <p className="text-light text-sm">Published: {new Date(blog.publishedAt).toLocaleDateString() ?? 'N?A'}</p>
                          </div>
                          <div className="mt-0 mx-auto text-center bg-white text-black overflow-hidden opacity-75">
                            <p className="text-2xl text-grey-darker font-medium mb-4">{blog.title}</p>
                            <p className="text-grey max-w-xs text-left mx-auto p-4 mb-6">{blog._rawExcerpt[0].children[0].text.substr(0, 100)}...</p>
                            <div>
                              <button type="button" onClick={() => onBlogPostClick(blog)} className="bg-blue hover:bg-blue-dark text-black border border-blue-dark rounded px-6 py-4">Read on &#8594;</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                  :
                  <div className="flex-1 box-content p-2 m-4">
                    <div className="box-content m-2 p-2 justify-center">
                      <p className="text-lg text-center text-bold underline text-mono">
                        No Blog Posts.</p>
                    </div>
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage


const pageQuery = graphql`
query MyQuery {
  site {
    id
  }
}
`